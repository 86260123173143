(function ($, viewport) {
    // Bootstrap 4 Divs
    var bootstrapDivs = {
        xs: $('<div class="device-xs d-block d-sm-none"></div>'),
        sm: $('<div class="device-sm d-none d-sm-block d-md-none"></div>'),
        md: $('<div class="device-md d-none d-md-block d-lg-none"></div>'),
        lg: $('<div class="device-lg d-none d-lg-block d-xl-none"></div>'),
        xl: $('<div class="device-xl d-none d-xl-block butts"></div>'),
    };
    viewport.use('bs4', bootstrapDivs);

    var currentBP;
    var gridNavigation;

    $(document).ready(function () {
        // remove on load burger checkbox active state
        // for firefox issues
        $('#burger').prop('checked', false);

        // animations
        $.animation.init();

        // promo cockpit logics
        $.promoCockpit.init();

        $.Navigation.init();

        $('body > footer [data-morphing]').fancyMorph({
            hash: 'morphing',
        });

        // tabbed content logics
        $.tabbedContent.init();

        currentBP = viewport.current();
        styleguide = checkIfStyleguide();
        // gridNavigation = $('.grid').grid({
        //     hideReverse: false
        // });
        var headerHeight = $('header.main').height();
        $('nav.main').css('top', headerHeight + 'px');

        replaceSVG();
        buildFeatureTeaser();
        objectFitImages();

        $(window).scroll(
            throttle(function (event) {
                calculateScroll();
            }, 200),
        );

        if ($('.CE_DOWNLOADS').length > 0) {
            resizeDownloadBoxes();
        }

        $(window).resize(function () {
            buildFeatureTeaser();
            if ($('.CE_DOWNLOADS').length > 0) {
                resizeDownloadBoxes();
            }

            // clone map only on special breakpoints
            // and if not already cloned
            // $.tabbedContent.cloneContent();
        });

        if ($('.CE_FORM').length > 0) {
            checkboxGroupValidation();

            // set scroll-padding-top, to see all error message from form
            $('.CE_FORM button[type="submit"]').on('click', function () {
                const root = document.querySelector(':root');
                root.style.setProperty('--scroll-padding-top', '200px');
            });
        }

        $('.CE_RANGEMAP_INFO_BUTTON').click(function () {
            if (!$(this).hasClass('active')) {
                $('.CE_RANGEMAP_INFO').css('visibility', 'visible').hide().fadeIn('slow');
                $('.CE_RANGEMAP_INFO_BUTTON').addClass('active');
            }
        });

        $('.CE_RANGEMAP_INFO a.close').click(function (e) {
            $('.CE_RANGEMAP_INFO').fadeOut('slow', function () {
                $(this).css('visibility', 'hidden');
                $('.CE_RANGEMAP_INFO_BUTTON').removeClass('active');
            });
            e.preventDefault();
        });

        $.fancybox.defaults.btnTpl.downloadOriginal = $.fancybox.defaults.btnTpl.download.replace(
            'data-fancybox-download',
            'data-original-download',
        );

        $('[data-fancybox]').fancybox({
            loop: true,
            buttons: ['share', 'downloadOriginal', 'thumbs', 'close'],
            beforeShow: function (instance, slide) {
                if (slide.type === 'image' && slide.$thumb) {
                    instance.$refs.container
                        .find('.fancybox-button--download')
                        .attr('href', slide.$thumb.data('original'))
                        .show();
                } else {
                    instance.$refs.container.find('.fancybox-button--download').hide();
                }
            },
        });

        $('[data-toggle="tooltip"]').tooltip();

        $('.openNavOverlay').click(function (e) {
            hideBurgerMenu();
            showHideSubnav('indirect');
            e.preventDefault();
        });

        $('.highlight-slider').highlightSlider();

        $('.showHideSubnav').click(function (e) {
            showHideSubnav('direct');
            e.preventDefault();
        });

        $('.burgermenu label').click(function (e) {
            // if (gridNavigation.isActive) {
            //     return gridNavigation.hide(function () {
            //         toggleBurgerMenu();
            //     });
            // }
            toggleBurgerMenu();
        });

        if ($('body.tmpl-Homepage').length > 0) {
            // setTimeout(function () {
            //     gridNavigation.show(function () {
            //         openYoutubeVideoFromPage(true);
            //     });
            // }, 1800);
        }

        $('.specialNavButton').click(function () {
            hideBurgerMenu();
            // gridNavigation.toggle(function () {
            //     $('body').toggleClass('grid-nav-open');
            // });
        });

        // Clicking on the close-item in the open gridNavigation calls
        //  - hide() method
        //  - removes class body.grid-nav-open (which is used for CSS animations for the DAI logo / closing-x)
        // $("body:not(.tmpl-Homepage) .hide-grid").click(function (e) {
        //     e.stopPropagation();
        //     e.preventDefault();
        //     gridNavigation.hide();
        //     $('body').removeClass('grid-nav-open');
        // });

        // prevent adding # to uri when logo is clicked on the startpage
        $(document).on('click', 'a.grid--container[href="#"]', function (e) {
            e.preventDefault();
        });

        // CE Overview Teaser
        //	- hover state if img is hovered
        $('.CE_OVERVIEW_TEASER_IMAGE a').hover(
            function () {
                $(this).parent().prev().children().addClass('hover');
            },
            function () {
                $(this).parent().prev().children().removeClass('hover');
            },
        );

        $('.js-loadVideo').click(function (e) {
            e.preventDefault();

            $elem = $(this).parent().parent().parent();
            $videoId = $elem.data('youtube-url');

            $elem.prepend(
                $('<iframe />')
                    .attr('src', $videoId)
                    .attr('width', 640)
                    .attr('height', 385)
                    .attr('frameborder', 0)
                    .attr('allowfullscreen', 'allowfullscreen')
                    .addClass('iframe'),
            );

            $('img', $elem).remove();
            $('.CE_YOUTUBE_GDPR_OVERLAY', $elem).remove();
        });

        $('a.js-jumplink[href^="#"]').on('click', function (event) {
            event.preventDefault();

            var $this = $(this),
                target = $this.attr('href');

            if (target && target !== '#') {
                $('html, body').animate(
                    {
                        scrollTop: $(target).offset().top - 200,
                    },
                    1000,
                );
            }
        });

        // sticky header polyfill for ie11
        // replaces whole scrollmonitor thing
        var stickybit = $('header.main').stickybits({
            noStyles: true,
            useStickyClasses: true,
            stickyBitStickyOffset: -71,
        });

        $('body').addClass('stickybit-' + stickybit.props.positionVal);

        $('a.jumplink[href^="#"]').on('click', function (event) {
            event.preventDefault();

            var $this = $(this),
                target = $this.attr('href');

            if (target && target !== '#') {
                $('html, body').animate(
                    {
                        scrollTop: $(target).offset().top - 90,
                    },
                    1000,
                );
            }
        });

        //STYLEGUIDE IFRAME EXISTS
        if (styleguide) {
        } else {
        }

        // sticky nav plugin
        $.sticky.init();
    });

    function openYoutubeVideoFromPage(autoplay) {
        if (autoplay === undefined) {
            autoplay = false;
        }
        if ($('body').hasClass('rootpage')) {
            autoplay = true;
        }
        if ($('body').attr('data-ytid') && $('body').attr('data-ytid') !== '' && autoplay === true) {
            var youtubeVideoId = $('body').data('ytid');
            $.fancybox.open({
                src: 'https://www.youtube-nocookie.com/embed/' + youtubeVideoId,
            });
        }
    }

    function buildFeatureTeaser() {
        var bp = window.getComputedStyle(document.querySelector('body'), ':after').getPropertyValue('content');
        if (bp == '"xl"' || bp == '"xxl"' || bp == '"xl"' || bp == '"xxl"' || bp == '"ultrawide"') {
            var boxHeight = $('.CE_FEATURE_TEASER_BOX').outerHeight() + 64;
            $('.CE_FEATURE_TEASER').height(boxHeight);
        } else {
            $('.CE_FEATURE_TEASER').height('auto');
        }
    }

    function checkIfStyleguide() {
        if (self !== top) {
            //Styleguide inside iframe
            return true;
        } else {
            if ($('body').hasClass('styleguideIframe')) {
                //no iframe but styleguide-page is loaded
                return true;
            } else {
                return false;
            }
        }
    }

    /*
     * Replace all SVG images with inline SVG
     */
    function replaceSVG() {
        $('img.svg').each(function () {
            var $img = $(this);
            var imgID = $img.attr('id');
            var imgClass = $img.attr('class');
            var imgURL = $img.attr('src');
            $.get(
                imgURL,
                function (data) {
                    // Get the SVG tag, ignore the rest
                    var $svg = $(data).find('svg');
                    // Add replaced image's ID to the new SVG
                    if (typeof imgID !== 'undefined') {
                        $svg = $svg.attr('id', imgID);
                    }
                    // Add replaced image's classes to the new SVG
                    if (typeof imgClass !== 'undefined') {
                        $svg = $svg.attr('class', imgClass + ' replaced-svg');
                    }
                    // Remove any invalid XML tags as per http://validator.w3.org
                    $svg = $svg.removeAttr('xmlns:a');
                    // Replace image with new SVG
                    $img.replaceWith($svg);
                },
                'xml',
            );
        });
    }

    function hideBurgerMenu() {
        $('.openNavOverlay').fadeOut(500);
        $('nav.main')
            .animate(
                {
                    opacity: 0,
                },
                500,
                function () {
                    $('.burgermenu').removeClass('open').children('#burger').prop('checked', false);
                    $('nav.main').hide();
                },
            )
            .removeClass('open');
    }

    function showBurgerMenu() {
        $('html, body').animate(
            {
                scrollTop: 0,
            },
            500,
        );
        buildMainNav();
        $('.openNavOverlay').fadeIn(500);
        $('nav.main')
            .show()
            .animate(
                {
                    opacity: 1,
                },
                500,
                function () {
                    $('.burgermenu').addClass('open');
                },
            )
            .addClass('open');
    }

    function toggleBurgerMenu(el) {
        if ($('.burgermenu').hasClass('open')) {
            hideBurgerMenu();
        } else {
            showHideSubnav('indirect');
            showBurgerMenu();
        }
    }

    function resizeDownloadBoxes() {
        var downloadHeight = 0;
        $('.CE_DOWNLOAD_ITEM').height('auto');
        $('.CE_DOWNLOAD_ITEM').each(function (index) {
            if (downloadHeight < $(this).height()) {
                downloadHeight = $(this).height();
            }
        });
        $('.CE_DOWNLOAD_ITEM').height(downloadHeight);
    }

    function buildMainNav() {
        var winHeight = $(window).height();
        var bodyHeight = $('body').height();
        if (bodyHeight < winHeight) {
            navHeight = winHeight;
        } else {
            navHeight = bodyHeight;
        }
        navHeight = navHeight - $('header.main').height();
        $('nav.main').height(navHeight);
    }

    function showHideSubnav(how) {
        if (how == 'indirect') {
            if ($('.showHideSubnav .arrow').hasClass('up')) {
                $('.showHideSubnav .arrow').removeClass('up');
                $('nav.sub.standard').fadeOut(400);
            }
        } else {
            if ($('.showHideSubnav .arrow').hasClass('up')) {
                $('.showHideSubnav .arrow').removeClass('up');
                $('.openNavOverlay').fadeOut(500);
            } else {
                hideBurgerMenu();
                $('.showHideSubnav .arrow').addClass('up');
                $('.openNavOverlay').fadeIn(500);
            }
            $('nav.sub.standard').fadeToggle(400);
        }
    }

    function checkboxGroupValidation() {
        $('.CE_FORM .checkbox-group').each(function (index) {
            var requiredCheckboxes = $(this).find(':checkbox[required]');
            requiredCheckboxes.change(function () {
                if (requiredCheckboxes.is(':checked')) {
                    requiredCheckboxes.removeAttr('required');
                } else {
                    requiredCheckboxes.attr('required', 'required');
                }
            });
        });
    }

    function calculateScroll() {
        var contentTop = [];
        var contentBottom = [];
        var winTop = $(window).scrollTop();
        var rangeTop = 100;
        var rangeBottom = 0;
        $('.STICKY_PAGE_NAVIGATION nav ul li')
            .find('a')
            .each(function () {
                item = $(this).attr('href');
                contentTop.push($(item).offset().top);
                contentBottom.push($(item).offset().top + $(item).height());
            });
        $.each(contentTop, function (i) {
            if (winTop > contentTop[i] - rangeTop && winTop < contentBottom[i] - rangeBottom) {
                $('.STICKY_PAGE_NAVIGATION li a').removeClass('active').eq(i).addClass('active');
            }
        });
    }
})(jQuery, ResponsiveBootstrapToolkit);

// Throttle function from underscore with removed references to underscore itself
function throttle(func, wait, options) {
    var context, args, result;
    var timeout = null;
    var previous = 0;
    if (!options) options = {};
    var later = function () {
        previous = options.leading === false ? 0 : Date.now();
        timeout = null;
        result = func.apply(context, args);
        if (!timeout) context = args = null;
    };
    return function () {
        var now = Date.now();
        if (!previous && options.leading === false) previous = now;
        var remaining = wait - (now - previous);
        context = this;
        args = arguments;
        if (remaining <= 0 || remaining > wait) {
            if (timeout) {
                clearTimeout(timeout);
                timeout = null;
            }
            previous = now;
            result = func.apply(context, args);
            if (!timeout) context = args = null;
        } else if (!timeout && options.trailing !== false) {
            timeout = setTimeout(later, remaining);
        }
        return result;
    };
}
